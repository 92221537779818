<template>
  <div class="video-player" v-if="file">
    <video width="100%" playsinline :autoplay="isAutoplay" :id="`video-${id}`" class="video-player-video" controls :poster="poster">
      <source :src="`${encodeURI(file.src)}`" />
    </video>
  </div>
</template>

<script>
export default {
  props: ["file", "poster", "isAutoplay"],
  data: function() {
    return {
      id: this.$global.fastID(),
    };
  },
};
</script>

<style lang="scss" scoped>
.video-player {
  position: relative;
  img,
  video {
    width: 100%;
  }
}
</style>
